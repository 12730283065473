/* #region Fonts */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&family=Rubik:wght@300;400;500;700&display=swap');
/* #endregion */
// @font-face {
//   font-family: 'Material Symbols Outlined';
//   font-style: normal;
//   font-weight: 100 700;
//   src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v88/kJEhBvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oFsLjBuVY.woff2) format('woff2');
// }

// .material-symbols-outlined {
//   font-family: 'Material Symbols Outlined';
//   font-weight: normal;
//   font-style: normal;
//   font-size: 24px;
//   line-height: 1;
//   letter-spacing: normal;
//   text-transform: none;
//   display: inline-block;
//   white-space: nowrap;
//   word-wrap: normal;
//   direction: ltr;
//   -webkit-font-feature-settings: 'liga';
//   -webkit-font-smoothing: antialiased;
// }

/* #region Variables */
$color-darker: #00313C;

$color-pastel: #EAA794;
$color-blue: #ABC7C9;
$color-brown: #D1CCBD;
$color-green: #96A480;
$color-orange: #34C6F4;
$color-red: #FF0000;
/* #endregion */

/* #region Function */
@function rem-calc($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}
/* #endregion */

/* #region Global */
  html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }
  body{
    font-family: 'Rubik', sans-serif;
    color: $color-darker;
    overflow-x:hidden;
    position: relative;
  }
  *{outline: none!important;}
/* #endregion */

/* #region Typography */
  h1,h2,h3,h4,h5,
  .h1,.h2,.h3,.h4,.h5 {
    font-family: 'Playfair Display', serif;
  }
  .h1, h1{
    font-size: rem-calc(55);
    font-weight: 900;
  }
  .h2, h2{
    font-size: rem-calc(38);
    margin-bottom: rem-calc(30);
    font-weight: 700;
  }
  .h3, h3{
    font-size: rem-calc(36);
    font-weight: 900;
    margin-bottom: rem-calc(20);
  }
  .h4, h4{
    font-weight: 700;
    line-height: 1.33334;
    margin-bottom: rem-calc(18);
  }
  .h5, h5{font-weight: 700;}
  p{
    margin-bottom: rem-calc(24);
    // &:last-child:not(.lead){margin-bottom: 0;}
  }
  ol,ul {
    li {
      font-size: rem-calc(16);
    }
  }

  [type=button],[type=submit], button {
    -webkit-appearance: none;
  }
/* #endregion */
.bg {
  &-darkder {
    background-color: $color-darker;
  }
  &-pastel {
    background-color: $color-pastel;
  }
  &-pink {
    background-color: $color-blue;
  }
  &-blue {
    background-color: $color-darker;
  }
  &-brown {
    background-color: $color-brown;
  }
  &-green {
    background-color: $color-green;
  }
}

.text {
  &-white {
    color: #fff;
  }
  &-darker {
    color: $color-darker;
  }
  &-pastel {
    color: $color-pastel;
  }
  &-pink {
    color: $color-pastel;
  }
  &-blue {
    color: $color-blue;
  }
  &-brown {
    color: $color-brown;
  }
  &-green {
    color: $color-green;
  }
  &-red{
    color: $color-red;
  }
}