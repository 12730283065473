/* #region Layout */
  .row:not(.no-gutters){
    margin-left: -10px;
    margin-right: -10px;
    .col, > [class*="col-"]{
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .section {
    padding-top: rem-calc(80);
    padding-bottom: rem-calc(80);
    &-border {
      border-bottom: solid 1px rgba($color-brown,.5);
    }
    .section {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .wave-bg {
    position: relative;
    .waving {
      position: absolute;
      left: 50%;
      bottom: 20px;
      transform: translateX(-50%);
      width: 100%;
      z-index: -1;
    }
  }
  .grey-bg {
        background-color: #eee;
    }
  .brown-bg {
    background-color: #f6f5f2;
  }
  .section.darker-bg{
      .feature-item p{
          color: #fff;
      }
  } 
  .virtual-holder{
        .grey-bg, .brown-bg, .section.darker-bg{
            background-color: #fff;
            .intro-wrap h4, .intro-wrap small{
                color: #96a480;
            }
            h2, p, span{
                color: #00313c;
            }
        }
  }
/* #endregion */

/* #region Form */
  .btn{
    font-size: rem-calc(15);
    font-weight: 500;
    border-radius: 10rem;
    padding: rem-calc(16) rem-calc(30);
    box-shadow: none;
    text-transform: uppercase;
    &.focus, &:focus{
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.10);
    }
    &-primary{
      background-color: $color-orange;
      border-color: $color-orange;
      &:hover, &:focus, 
      &:not(:disabled):not(.disabled).active, 
      &:not(:disabled):not(.disabled):active, 
      .show > &.dropdown-toggle{
        background-color: darken($color-orange, 5%);
        border-color: darken($color-orange, 5%);
      }
    }
    &-secondary{
      background-color: $color-blue;
      border-color: $color-blue;
      color: $color-darker;
      &:hover, &:focus, 
      &:not(:disabled):not(.disabled).active, 
      &:not(:disabled):not(.disabled):active, 
      .show > &.dropdown-toggle{
        background-color: darken($color-blue, 5%);
        border-color: darken($color-blue, 5%);
        color: $color-darker;
      }
    }
    &-outline {
      padding: rem-calc(15) rem-calc(32);
      background: transparent;
      border: solid 3px $color-orange;
      color: $color-orange;
      &:hover,
      &:focus {
        color: #fff;
        background: $color-orange !important;
      }
    }
    &-w160{min-width: rem-calc(160);}
  }

  .custom-control{
    &-label{
      &::before{
        background-color: #F7F7F8;
        border-color: rgba($color-darker, .4);
      }
      .label-selected{display: none;}
    }
    &-input:checked ~ .custom-control-label{
      &::before{
        background-color: $color-darker;
        border-color: $color-darker;
      }
      .label-selected{display: inline;}
    }
  }

  .custom-checkbox .custom-control-label::before {
    border-radius: rem-calc(2);
  }

  .dropdown{
    &-toggle::after{
      margin-left: rem-calc(8);
      border:0;
      width:rem-calc(12);
      height:rem-calc(8);
      background: url("data:image/svg+xml,%3Csvg%20width%3D'10'%20height%3D'6'%20viewBox%3D'0%200%2010%206'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M0.582031%200.0429688L0.0429688%200.582031L4.73047%205.26953L5%205.52734L5.26953%205.26953L9.95703%200.582031L9.41797%200.0429688L5%204.46094L0.582031%200.0429688Z'%20fill%3D'%2300313C'%2F%3E%3C%2Fsvg%3E") no-repeat;
      background-size: rem-calc(12) rem-calc(8);
      vertical-align: rem-calc(1);
      transition: transform .2s ease;
    }
    &.show .dropdown-toggle::after{transform: rotateX(-180deg);}
    &-menu{
      margin-top: 0;
      border:0;
      border-radius: 0;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.10);
    }
    &-item{
      padding-top: rem-calc(8);
      padding-bottom: rem-calc(8);
      &:hover, &:focus{
        color: #fff;
        background: $color-orange;
      }
      &.active, &:active{
        color: $color-orange!important;
      }
    }
  }

  .bootstrap-select{
    width:100%!important;
    display: block!important;
    > .dropdown-toggle{
      background-color: transparent;
      border-color: $color-darker;
      box-shadow: none;
      font-weight: 600;
      &.bs-placeholder, &.bs-placeholder:active, &.bs-placeholder:focus, &.bs-placeholder:hover{
        color: $color-darker;
      }
      &::after{
        margin-top: 0;
        width:rem-calc(14);
      }
    }
    .dropdown-menu li a:hover{color: #fff;}
  }

  .bootstrap-tagsinput {
    display: block;
    padding: rem-calc(7) 0 rem-calc(6);
    color: $color-darker;
    border-bottom: 1px solid rgba($color-darker, .4);
    width: 100%;
    cursor: text;
    input {
      border: none;
      box-shadow: none;
      outline: none;
      background-color: transparent;
      padding: 0;
      margin: 0;
      width: auto !important;
      max-width: inherit;
      font-size: 1rem;
      font-weight: 600;
      height: rem-calc(36);
      color: $color-darker;
      &::placeholder{color: $color-darker;}
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
    .tag {
      background-color: rgba(#fff, .8);
      border-radius: rem-calc(2);
      display: inline-block;
      font-size: rem-calc(14);
      font-weight: 600;
      line-height: 1.42857;
      padding:rem-calc(8) rem-calc(10);
      color: $color-darker;
      [data-role="remove"] {
        margin-left:8px;
        cursor:pointer;
        vertical-align: -2px;
        display: inline-block;
        &:after{
          display: block;
          content: "×";
          padding:0px 2px;
          font-size: rem-calc(20);
          line-height: rem-calc(16);
        }
      }
    }
  }
/* #endregion */

/* #region Navbar */
  .navtop{
    &-search{
      position: relative;
      margin-left: rem-calc(15);
      display: flex;
      align-items: center;
      margin-right: rem-calc(10);
      .form-control{
        position: absolute;
        right: rem-calc(-10);
        top: 50%;
        transform: translateY(-50%);
        padding:0;
        height: rem-calc(40);
        border-radius: rem-calc(2);
        border:0;
        width: 0;
        font-size: rem-calc(12);
        line-height: 1.538462;
        font-weight: 500;
        transition: all .3s ease;
        box-shadow: none;
      }
      .btn-search{
        position: relative;
        z-index: 10;
        display: block;
        padding:rem-calc(1.5);
        background-color: transparent;
        border:0;
        color: #fff;
        &:hover{color: $color-orange;}
        svg{display: block;}
      }
      &.active{
        .form-control{
          width: 250px;
          padding:rem-calc(5) rem-calc(36) rem-calc(5) rem-calc(12);
          background: #f1f2f3;
        }
        .btn-search{
          color: $color-darker;
        }
      }
    }
  }
  .navbar{
    padding:rem-calc(30) rem-calc(40);
    transition: all .15s linear;
    &-brand{
      padding-top: 0;
      padding-bottom: 0;
      color: #fff;
      margin-right: rem-calc(24);
      &:hover{color: #fff;}
      svg{
        display: block;
        transition: color .2s ease;
      }
      img {
        width: 180px;
        @media (max-width: 575px) {
          width: 110px;
        }
        &.scroll {
          display: none;
        }
      }
    }
    &-nav{
      .nav-item{
        .nav-link{
          color: #fff;
          font-size: rem-calc(15);
          font-weight: 500;
          padding:rem-calc(10) rem-calc(14);
          text-transform: uppercase;
          &:hover{color: $color-orange;}
        }
        &.active .nav-link{color: $color-orange;}
      }
      .dropdown{
        &-toggle{
          &::after{background-image: url("data:image/svg+xml,%3Csvg%20width%3D'10'%20height%3D'6'%20viewBox%3D'0%200%2010%206'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M0.582031%200.0429688L0.0429688%200.582031L4.73047%205.26953L5%205.52734L5.26953%205.26953L9.95703%200.582031L9.41797%200.0429688L5%204.46094L0.582031%200.0429688Z'%20fill%3D'white'%2F%3E%3C%2Fsvg%3E");}
          &:hover::after{background-image: url("data:image/svg+xml,%3Csvg%20width%3D'10'%20height%3D'6'%20viewBox%3D'0%200%2010%206'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M0.582031%200.0429688L0.0429688%200.582031L4.73047%205.26953L5%205.52734L5.26953%205.26953L9.95703%200.582031L9.41797%200.0429688L5%204.46094L0.582031%200.0429688Z'%20fill%3D'%234A9462'%2F%3E%3C%2Fsvg%3E");}
        }
        &-item{
          font-size: rem-calc(15);
          font-weight: 500;
          padding:rem-calc(8) rem-calc(15);
        }
      }
    }
    &.scrolled, &.navbar-white{
      background-color: #fff;
      box-shadow: 0 2px 10px 0 rgba(0,0,0,0.10);
      padding: 10px 40px;
      .navbar-nav{
        .nav-item{
          .nav-link{
            color: $color-darker;
            &:hover{color: $color-orange;}
          }
          &.active .nav-link{color: $color-orange;}
        }
        .dropdown{
          &-toggle{
            &::after{background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.582.043L.043.582 4.73 5.27l.27.257.27-.257L9.957.582 9.418.043 5 4.461.582.043z' fill='%2300313C'/%3E%3C/svg%3E")}
            &:hover::after{background-image: url("data:image/svg+xml,%3Csvg%20width%3D'10'%20height%3D'6'%20viewBox%3D'0%200%2010%206'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M0.582031%200.0429688L0.0429688%200.582031L4.73047%205.26953L5%205.52734L5.26953%205.26953L9.95703%200.582031L9.41797%200.0429688L5%204.46094L0.582031%200.0429688Z'%20fill%3D'%234A9462'%2F%3E%3C%2Fsvg%3E");}
          }
        }
      }
      .navbar-brand{
        color: $color-darker;
        img {
          &.no-scroll {
            display: none;
          }
          &.scroll {
            display: block;
          }
        }
      }
      .hamburger-inner{
        &, &::before, &::after{
          background-color: $color-darker;
        }
      }
      .navtop-search .btn-search:hover {
        color: $color-blue;
      }
      .contact-wrap .btn-tel:hover {
        color: $color-blue;
      }
    }
  }
  .contact-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn-tel {
      color: #fff;
      font-size: rem-calc(15);
      font-weight: 500;
      display: inline-flex;
      align-items: center;
      margin-left: rem-calc(20);
      margin-right: rem-calc(20);
      text-decoration: none;
      svg {
        margin-right: rem-calc(5);
      }
      &:hover{color: $color-orange;}
    }
  }
  .hamburger {
    $this: &;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0 rem-calc(-14) 0 rem-calc(8);
    overflow: visible; 
    display: none;
    padding:rem-calc(15) rem-calc(14);
    &-box{
      width: 22px;
      height: 20px;
      display: block;
      position: relative;
    }
    &-inner{
      display: block;
      top: 50%;
      margin-top: -1px;
      &, &::before, &::after{
        width: 22px;
        height: 2px;
        background-color: #fff;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
      }
      &::before, &::after{
        content: "";
        display: block;
      }
      &::before {
        top: -6px; 
        transition: top 0.075s 0.12s ease,opacity 0.075s ease;
      }
      &::after{
        bottom: -6px;
        transition: bottom 0.075s 0.12s ease,
        transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
      &{
        transition-duration: 0.075s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }
    &.is-active{
      #{$this}-inner{
        transform: rotate(45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        &::before{
          top: 0;
          opacity: 0;
          transition: top 0.075s ease, opacity 0.075s 0.12s ease; 
        }
        &::after{
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
  .navigation{
    position: fixed;
    top:0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width:300px;
    z-index: 1040;
    display:none;
    overflow:hidden;
    transform: translate3d(100%,0,0);
    transition: transform 0.4s;
    transition-timing-function: cubic-bezier(0.7,0,0.3,1);
    background-color: #fff;
    .navigation-open &{
      transform: translate3d(0,0,0);
      transition: transform 0.5s;
    }
    &-header{
      padding:1rem rem-calc(24);
    }
    .close{
      display: block;
      float: none;
      color: $color-darker;
      font-family: sans-serif;
      font-size: 2.5rem;
      line-height: 1;
      margin-left: auto;
      transition: all .1s ease;
      opacity: 1!important;
      font-weight: 300;
    }
    &-body{
      overflow:hidden;
      position: relative;
      z-index: 2;
    }
    &-backdrop{
      position:fixed;
      top:0;
      bottom:0;
      right:0;
      left:0;
      background-color:rgba(#000,.75);
      z-index:1034;
      opacity:0;
      visibility:hidden;
      transition: all 0.3s ease;
      .navigation-open &{
        opacity: 1;
        visibility: visible;
      }
    }
    .nav-link{
      font-size: rem-calc(14);
      text-transform: uppercase;
      line-height: 1.538462;
      font-weight: 500;
      padding:rem-calc(10) rem-calc(24);
      color: $color-darker;
      &[data-toggle="collapse"]{
        padding-right: rem-calc(40);
      }
    }
    .collapse-body .nav-link{padding-left: rem-calc(36);}
  }
  .navigation-open{
    overflow: hidden!important;
    height: 100%!important;
  }

  [data-toggle="collapse"]{
    position: relative;
    .icon-chevron-down{
      position: absolute;
      right:rem-calc(24);
      top:rem-calc(16);
      transition: transform .2s ease;
    }
    &:not(.collapsed) .icon-chevron-down{
      transform: rotateX(-180deg);
    }
  }

  .navbar.scrolled,
  .navbar.navbar-white {
    .navtop-search .btn-search,
    .contact-wrap .btn-tel {
      color: $color-darker;
    }
  }
/* #endregion */

/* #region Big hero */
  .big-header {
    display: flex;
    height: 650px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 185px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      z-index: 2;
    }
    &-carousel {
      width: 65%;
      .header-carousel {
        height: 100%;
        .owl {
          &-stage-outer,
          &-stage,
          &-item,
          &-item-inner {
            height: 100%;
          }
          &-item-inner {
            position: relative;
            overflow: hidden;
            display:flex;
            img {
              width: 100%;
              min-height: 100%;
              object-fit: cover;
            }
          }
          &-dots {
            position: absolute;
            bottom: 30px;
            left: 50px;
            .owl-dot {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: rgba($color-darker, .4);
              margin: rem-calc(10);
              transition: background-color .2s ease-out;
              &.active,
              &:hover {
                background: $color-darker;
              }
            }
          }
        }
      }
    }
    &-content {
      background: $color-darker;
      width: 35%;
      color: #fff;
      padding: rem-calc(40);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      z-index: 3;
      p {
        margin-top: rem-calc(120);
        font-size: rem-calc(24);
        font-weight: 500;
        margin-bottom: rem-calc(30);
      }
      .link {
        color: $color-orange;
        font-size: rem-calc(18);
        font-weight: 500;
        margin-bottom: rem-calc(15);
        text-decoration: none;
      }
    }
    .h1 {
      z-index: 4;
      font-size: rem-calc(60);
      font-weight: 400;
      text-transform: uppercase;
      white-space: nowrap;
      color: $color-darker;
      &.title-js {
        position: absolute;
        top: calc(50% - 90px);
        right: 35%;
        span:last-child {
          color: #fff;
        }
      }
    }
    .quick-access {
      position: absolute;
      left: 60px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      background: $color-brown;
      color: $color-darker;
      padding: rem-calc(30);
      width: 240px;
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
      span {
        font-size: rem-calc(18);
        font-weight: 400;
        display: inline-block;
        margin-bottom: rem-calc(14);
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          border-bottom: solid 1px rgba($color-darker, .2);
          a {
            color: $color-darker;
            text-decoration: none;
            font-size: rem-calc(15);
            font-weight: 500;
            text-transform: uppercase;
            display: block;
            padding: rem-calc(10) 0;
            width: 100%;
            position: relative;
            transition: color .2s ease-out;
            &::after {
              content: '';
              position: absolute;
              top: 50%;
              right: 0;
              transition: all .2s ease-out;
              transform: translate(-10px,-50%);
              opacity: 0;
              width: 18px;
              height: 10px;
              background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'18'%20height%3D'12'%20viewBox%3D'0%200%2018%2012'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M12.8906%200.960938L11.8359%202.03906L15.0469%205.25H0V6.75H15.0469L11.8359%209.96094L12.8906%2011.0391L17.9297%206L12.8906%200.960938Z'%20fill%3D'#34c6f4'%2F%3E%3C%2Fsvg%3E") no-repeat;
            }
            &:hover,
            &:focus {
              color: $color-orange;
              &::after {
                opacity: 1;
                transform: translate(0,-50%);
              }
            }
          }
          &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
          }
        }
      }
    }
  }
/* #endregion */

/* #region Footer */
  .subscribe {
    background: $color-darker;
    color: #fff;
    padding: rem-calc(26) 0;
    &-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        margin: 0;
        font-size: rem-calc(24);
        font-weight: 500;
        text-transform: uppercase;
      }
      input {
        margin-left: rem-calc(30);
        width: 100%;
        max-width: 285px;
        background: transparent;
        color: $color-blue;
        font-size: rem-calc(24);
        font-weight: 500;
        border: none;
        border-bottom: solid 1px $color-blue;
        &::placeholder {
          color: $color-blue;
        }
        border-radius: 0 !important;
        &:focus {
          background: transparent;
          color: $color-blue;
        }
      }
    }
  }
  .footer {
    background: $color-brown;
    &-top {
      padding: rem-calc(80) 0;
      border-bottom: solid 1px rgba($color-darker,.2);
      p {
        font-size: rem-calc(16);
        margin-bottom: rem-calc(5);
        span {
          display: inline-block;
          color: $color-orange;
          font-weight: 700;
          margin-right: rem-calc(5);
        }
      }
      .footer-address {
        a {
          color: #01313c;
          text-decoration: underline;
        }
      }
      .link {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $color-darker;
        font-weight: 500;
        font-size: rem-calc(18);
        text-decoration: none;
        margin-bottom: rem-calc(20);
        &:hover,
        &:focus {
          color: $color-orange;
        }
      }
    }
    &-logo {
      display: inline-block;
      color: $color-darker;
      margin-bottom: rem-calc(30);
      &:hover {
        color: $color-darker;
      }
    }
    &-list {
      margin: 0;
      margin-bottom: rem-calc(25);
      padding: 0;
      list-style: none;
      li {
        margin-bottom: rem-calc(15);
        a {
          color: $color-darker;
          font-size: rem-calc(15);
          font-weight: 500;
          text-transform: uppercase;
          text-decoration: none;
          &:hover,
          &:focus {
            color: $color-orange;
          }
        }
      }
    }
    &-bottom {
      padding: rem-calc(26) 0;
      &-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          margin: 0;
          font-size: rem-calc(14);
        }
        a {
          color: inherit;
          position: relative;
          text-decoration: none;
          &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 1px;
            background: $color-darker;
          }
        }
      }
    }
    .btn-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .socials {
      margin-top: rem-calc(30);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      a {
        margin: 5px;
        color: $color-darker;
        &:hover {
          color: $color-orange;
        }
      }
    }
    .sm-text {
      margin-top: rem-calc(20);
      font-size: rem-calc(14);
      a {
        color: inherit;
        text-decoration: underline;
      }
    }
  }
/* #endregion */

/* #region Intro */
  .intro-wrap {
    margin-bottom: rem-calc(15);
    position: relative;
    z-index: 1;
    &.center {
      text-align: center;
    }
    small {
      font-size: rem-calc(18);
      font-weight: 700;
      color: $color-blue;
      text-transform: uppercase;
      margin-bottom: rem-calc(10);
    }
    h2 {
      font-size: rem-calc(45);
      font-weight: 400;
      margin-bottom: rem-calc(20);
    }
    p {
      display: inline-block;
      max-width: 600px;
      font-size: rem-calc(17);
      margin-bottom: rem-calc(25);
    }
    .btn-wrap {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: calc(100% + 15px);
      margin-left: -15px;
      a {
        margin: 5px 15px;
        &.link {
          font-size: rem-calc(18);
          font-weight: 500;
          text-decoration: none;
          color: $color-darker;
        }
      }
    }
  }
  .cta-wrap {
    text-align: center;
    padding-top: rem-calc(30);
  }
/* #endregion */

/* #region Text w Image */
  .has-bg {
    background-repeat: no-repeat;
    background-position-y: 65px;
    background-size: 65% auto;
    &.right {
      background-position-x: 100%;
    }
    &.left {
      background-position-x: 0;
    }
  }
  .textside {
    background: #fff;
    padding: rem-calc(40);
    margin-top: rem-calc(50);
    position: relative;
    z-index: 1;
    margin-bottom: rem-calc(30);
    p {
      font-size: rem-calc(16);
      margin-bottom: rem-calc(25);
    }
    ul {
      padding: 0;
      margin: 0;
      margin-bottom: rem-calc(25);
      list-style: none;
      li {
        padding-left: rem-calc(30);
        position: relative;
        margin-bottom: rem-calc(10);
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          content: '';
          width: 20px;
          height: 20px;
          background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'20'%20height%3D'20'%20viewBox%3D'0%200%2020%2020'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M10%200.25C4.60059%200.25%200.25%204.60059%200.25%2010C0.25%2015.3994%204.60059%2019.75%2010%2019.75C15.3994%2019.75%2019.75%2015.3994%2019.75%2010C19.75%208.95117%2019.6094%207.90527%2019.2344%206.92969L18.0156%208.125C18.165%208.72559%2018.25%209.32617%2018.25%2010C18.25%2014.5762%2014.5762%2018.25%2010%2018.25C5.42383%2018.25%201.75%2014.5762%201.75%2010C1.75%205.42383%205.42383%201.75%2010%201.75C12.25%201.75%2014.2715%202.64648%2015.6953%204.07031L16.75%203.01562C15.0244%201.29004%2012.625%200.25%2010%200.25ZM18.4609%203.46094L10%2011.9219L6.78906%208.71094L5.71094%209.78906L9.46094%2013.5391L10%2014.0547L10.5391%2013.5391L19.5391%204.53906L18.4609%203.46094Z'%20fill%3D'%23ABC7C9'%2F%3E%3C%2Fsvg%3E") no-repeat;;
        }
      }
    }
    *:last-child {
      margin-bottom: 0;
    }
  }
  .imgside {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    img {
      width: 100%;
      height:auto;
    }
  }
/* #endregion */

/* #region Map */
  .content-map {
    width: auto;
    padding-left: rem-calc(65);
    margin-top: rem-calc(30);
    padding-right: rem-calc(65);
    position: relative;
    &-inner {
      width: 100%;
      height: 650px;
    }
    &-filter {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 240px;
      background: $color-brown;
      padding: rem-calc(30);
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
      span {
        font-size: rem-calc(18);
        display: inline-block;
        margin-bottom: rem-calc(20);
      }
      .custom-checkbox {
        margin-right: 0 !important;
        padding-top: rem-calc(10);
        padding-bottom: rem-calc(10);
        border-bottom: solid 1px rgba($color-darker,.2);
        &:last-child {
          border-bottom: 0;
        }
        .custom-control-label {
          font-size: rem-calc(15);
          font-weight: 500;
          text-transform: uppercase;
        }
        .custom-control-input:checked~.custom-control-label {
          color: #fff;
        }
        .custom-control-input:not(:disabled):active~.custom-control-label::before {
          background-color: $color-darker;
          border-color: $color-darker;
        }
        .custom-control-input:focus:not(:checked)~.custom-control-label::before {
          border-color: $color-darker;
          box-shadow: 0 0 0 0.2rem rgba($color-darker,.25)
        }
        .custom-control-input:focus~.custom-control-label::before {
          box-shadow: 0 0 0 0.2rem rgba($color-darker,.25)
        }
        .custom-control-label:before {
          border-radius: 0;
        }
      }
    }
  }
  .mapItem {
    background: #F6F5F2;
    &-img {
      position: relative;
      width: 100%;
      padding-top: 45%;
      overflow: hidden;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        min-height: 100%;
        object-fit: cover;
      }
    }
    &-content {
      padding: rem-calc(20) rem-calc(20);
      h3 {
        font-family: 'Rubik', sans-serif;
        font-size: rem-calc(24);
        font-weight: 500;
        margin-bottom: rem-calc(15);
      }
      p {
        margin-bottom: rem-calc(20);
        font-size: rem-calc(16);
      }
      ul {
        margin: 0;
        margin-bottom: rem-calc(10);
        padding: 0;
        list-style: none;
        column-count: 2;
        column-gap: 20px;
        li {
          padding-left: 25px;
          position: relative;
          margin-bottom: rem-calc(10);
          font-size: rem-calc(16);
          &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: 20px;
            height: 20px;
            background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'20'%20height%3D'20'%20viewBox%3D'0%200%2020%2020'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M10%200.25C4.60059%200.25%200.25%204.60059%200.25%2010C0.25%2015.3994%204.60059%2019.75%2010%2019.75C15.3994%2019.75%2019.75%2015.3994%2019.75%2010C19.75%208.95117%2019.6094%207.90527%2019.2344%206.92969L18.0156%208.125C18.165%208.72559%2018.25%209.32617%2018.25%2010C18.25%2014.5762%2014.5762%2018.25%2010%2018.25C5.42383%2018.25%201.75%2014.5762%201.75%2010C1.75%205.42383%205.42383%201.75%2010%201.75C12.25%201.75%2014.2715%202.64648%2015.6953%204.07031L16.75%203.01562C15.0244%201.29004%2012.625%200.25%2010%200.25ZM18.4609%203.46094L10%2011.9219L6.78906%208.71094L5.71094%209.78906L9.46094%2013.5391L10%2014.0547L10.5391%2013.5391L19.5391%204.53906L18.4609%203.46094Z'%20fill%3D'%23ABC7C9'%2F%3E%3C%2Fsvg%3E") no-repeat;
          }
        }
      }
      .btn-wrap {
        margin-left: -10px;
        width: calc(100% + 20px);
        a {
          margin: rem-calc(5) rem-calc(10);
          &.link {
            font-size: rem-calc(18);
            font-weight: 500;
            color: $color-darker;
            text-decoration: none;
          }
        }
      }
    }
  }
  .gm-style {
    .gm-style-iw-c {
      padding: 0;
      border-radius: 0;
    }
    .gm-style-iw-d {
      overflow: auto !important;
    }
    .gm-style-iw-a {
      button[aria-label="Close"] {
        left: 0 !important;
        top: 0 !important;
        img {
          width: 22px !important;
          height: 22px !important;
        }
      }
    }
    .gm-style-iw-t::after {
      display: none;
    }
  }
  .filter-dropdown {
    .dropdown-toggle {
      border-radius: 0;
      border: 0;
      background: rgba(#fff,.4);
      padding: rem-calc(13);
      font-size: rem-calc(15);
      font-weight: 500;
      &:focus {
        outline: none !important;
      }
    }
    .dropdown-menu {
      li {
        a {
          padding: rem-calc(6) rem-calc(12);
          &.active,
          &:hover,
          &:active {
            background: $color-orange;
            color: #fff !important;
          }
          span.text {
            margin-bottom: 0;
            font-size: rem-calc(15);
            text-transform: uppercase;
          }
        }
      }
    }
  }
/* #endregion */

/* #region Masonry */
  .articles-masonry {
    margin-left: 0;
    margin-right: -15px;
    padding-top: rem-calc(30);
    margin-bottom: rem-calc(20);
  }
  .articles-masonry-item {
    margin: 0 auto rem-calc(15);
    display: block;
    width: calc(33.33333% - 15px);
    &:hover {
      .article-wrap:after {
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'18'%20height%3D'12'%20viewBox%3D'0%200%2018%2012'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M12.8906%200.960938L11.8359%202.03906L15.0469%205.25H0V6.75H15.0469L11.8359%209.96094L12.8906%2011.0391L17.9297%206L12.8906%200.960938Z'%20fill%3D'#4A9462'%2F%3E%3C%2Fsvg%3E") no-repeat;
        background-position: center;
      }
      .article-content {
        color: $color-orange;
      }
    }
    &.no-image {
      .article-wrap {
        min-height: 350px;
        background-color: $color-brown;
        &::before {
          display: none;
        }
        &::after {
          background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'18'%20height%3D'12'%20viewBox%3D'0%200%2018%2012'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M12.8906%200.960938L11.8359%202.03906L15.0469%205.25H0V6.75H15.0469L11.8359%209.96094L12.8906%2011.0391L17.9297%206L12.8906%200.960938Z'%20fill%3D'#00313c'%2F%3E%3C%2Fsvg%3E") no-repeat;
          background-position: center;
        }
        .article-content {
          color: $color-darker;
        }
      }
      // &:hover {
      //   .article-wrap:after {
      //     background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'18'%20height%3D'12'%20viewBox%3D'0%200%2018%2012'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M12.8906%200.960938L11.8359%202.03906L15.0469%205.25H0V6.75H15.0469L11.8359%209.96094L12.8906%2011.0391L17.9297%206L12.8906%200.960938Z'%20fill%3D'#4A9462'%2F%3E%3C%2Fsvg%3E") no-repeat;
      //     background-position: center;
      //   }
      //   .article-content {
      //     color: $color-orange;
      //   }
      // }
    }
  }
  .article {
    &-wrap {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 80%;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
        z-index: 0;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 30px;
        right: 30px;
        width: 24px;
        height: 24px;
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'18'%20height%3D'12'%20viewBox%3D'0%200%2018%2012'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M12.8906%200.960938L11.8359%202.03906L15.0469%205.25H0V6.75H15.0469L11.8359%209.96094L12.8906%2011.0391L17.9297%206L12.8906%200.960938Z'%20fill%3D'#fff'%2F%3E%3C%2Fsvg%3E") no-repeat;
        background-position: center;
      }
      img {
        width: 100%;
      }
    }
    &-content {
      position: absolute;
      z-index: 1;
      bottom: rem-calc(30);
      left: rem-calc(30);
      text-decoration: none;
      color: #fff;
      max-width: calc(100% - 100px);
      *:last-child {
        margin-bottom: 0;
      }
      small {
        font-size: rem-calc(16);
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: rem-calc(5);
      }
      h3 {
        margin-bottom: rem-calc(10);
        font-size: rem-calc(20);
        font-weight: 500;
        font-family: 'Rubik', sans-serif;
      }
      p {
        font-size: rem-calc(16);
        margin-bottom: 0;
      }
    }
    &-message {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 0;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: rem-calc(5) rem-calc(7);
      span {
        font-size: rem-calc(13);
        font-weight: 500;
      }
    }
  }
/* #endregion */

/* #region Filters */
  .filter-bar {
    background: $color-brown;
    padding: rem-calc(20);
    span {
      font-size: rem-calc(18);
      margin-bottom: rem-calc(10);
      display: inline-block;
    }
  }
/* #endregion */

/* #region Home-item */
  .home-carousel-wrap {
    margin-top: rem-calc(40);
    position: relative;
    .home-carousel,
    .home-carousel-two {
      position: static;
    }
    .owl-nav {
      .owl-prev,
      .owl-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 48px;
        height: 48px;
        background-color: $color-blue !important;
        display: flex;
        align-items: center;
        justify-content: center;
        &.disabled {
          opacity: .4;
        }
        span {
          font-size: rem-calc(36);
          font-family: 'Rubik';
          font-weight: 300;
          color: #fff;
        }
      }
      .owl-prev {
        left: 0;
      }
      .owl-next {
        right: 0;
      }
    }
  }
  .homeItem {
    background: #F6F5F2;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);
    margin-bottom: rem-calc(30);
    position: relative;
    overflow: hidden;
    &>span {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      background: $color-darker;
      padding: 5px;
      color: #fff;
      text-transform: uppercase;
      font-size: rem-calc(15);
    }
    &-img {
      position: relative;
      width: 100%;
      padding-top: 56.25%;
      display: block;
      overflow: hidden;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        min-height: 100%;
        object-fit: cover;
        transform: translate(-50%, -50%);
      }
    }
    &-content {
      padding: rem-calc(20);
    }
    &-features {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: rem-calc(30);
      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        svg {
          color: $color-blue;
        }
        img {
          width: auto !important;
          margin-right: rem-calc(5);
        }
      }
    }
    &-inner {
      h3 {
        font-family: 'Rubik', sans-serif;
        font-size: rem-calc(24);
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: rem-calc(15);
        a {
          text-decoration: none;
          color: $color-darker;
          &:hover {
            text-decoration: none;
          }
        }
      }
      p {
        font-size: rem-calc(16);
        margin-bottom: rem-calc(30);
      }
      ul {
        margin-bottom: rem-calc(30);
        padding: 0;
        list-style: none;
        li {
          padding-left: rem-calc(30);
          position: relative;
          margin-bottom: rem-calc(10);
          &::before {
            position: absolute;
            top: 1px;
            left: 0;
            content: '';
            width: 20px;
            height: 20px;
            background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'20'%20height%3D'20'%20viewBox%3D'0%200%2020%2020'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M10%200.25C4.60059%200.25%200.25%204.60059%200.25%2010C0.25%2015.3994%204.60059%2019.75%2010%2019.75C15.3994%2019.75%2019.75%2015.3994%2019.75%2010C19.75%208.95117%2019.6094%207.90527%2019.2344%206.92969L18.0156%208.125C18.165%208.72559%2018.25%209.32617%2018.25%2010C18.25%2014.5762%2014.5762%2018.25%2010%2018.25C5.42383%2018.25%201.75%2014.5762%201.75%2010C1.75%205.42383%205.42383%201.75%2010%201.75C12.25%201.75%2014.2715%202.64648%2015.6953%204.07031L16.75%203.01562C15.0244%201.29004%2012.625%200.25%2010%200.25ZM18.4609%203.46094L10%2011.9219L6.78906%208.71094L5.71094%209.78906L9.46094%2013.5391L10%2014.0547L10.5391%2013.5391L19.5391%204.53906L18.4609%203.46094Z'%20fill%3D'%23ABC7C9'%2F%3E%3C%2Fsvg%3E") no-repeat;;
          }
        }
      }
      .link-wrap {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .link {
          font-weight: 500;
          font-size: rem-calc(18);
          text-decoration: none;
          color: $color-darker;
          display: inline-block;
          margin-right: rem-calc(40);
          &:last-child {
            margin-right: 0;
          }
          &.link-primary {
            color: $color-orange;
          }
        }
      }
    }
  }
/* #endregion */

/* #region Review */
  .review {
    &-wrap {
      padding-top: rem-calc(60);
      padding-bottom: rem-calc(60);
      margin-bottom: rem-calc(70);
      background-color: #f6f5f2;
      position: relative;
      .review-wrap-bg {
        position: absolute;
        top: 0;
        right: 0;
        background-image: url('../img/bg-review.png');
        background-repeat: no-repeat;
        background-size: 135%;
        background-position-x: 100%;
        background-position-y: center;
        width: 50%;
        height: 100%;
        @media screen and (max-width: 991px) {
          display: none;
        }
      }
      &.left {
        .review-wrap-bg {
          right: unset;
          left: 0;
        }
      }
      .review-carousel {
        position: static;
      }
      .owl-dots {
        position: absolute;
        top: calc(100% + 40px);
        left: 50%;
        transform: translateX(-50%);
        .owl-dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: rgba($color-darker,.4);
          margin: 5px 10px;
          &.active {
            background-color: $color-darker;
          }
        }
      }
    }
    &-text {
      position: relative;
      padding-top: rem-calc(60);
      margin-right: rem-calc(60);
      overflow: visible;
      &::before,
      &::after {
        position: absolute;
        color: $color-blue;
        font-size: rem-calc(100);
        font-family: 'Playfair', serif;
        z-index: 0;
      }
      &::before {
        content: '“';
        top: -30px;
        left: 0;
      }
      &::after {
        content: '”';
        bottom: -85px;
        right: 0;
      }
      p {
        font-size: rem-calc(16);
        margin-bottom: rem-calc(30);
      }
      h3 {
        font-family: 'Rubik',sans-serif;
        font-size: rem-calc(20);
        font-weight: 500;
        margin-bottom: 0;
      }
    }
    &-media {
      width: 100%;
      padding-top: 56.25%;
      position: relative;
      overflow: hidden;
      img,
      iframe {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        min-height: 100%;
        object-fit: cover;
        border: 0;
      }
      .btn-play {
        width: 48px;
        height: 48px;
        min-height: unset;
        object-fit: unset;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        img {
          width: 100%;
          height: 100%;
          position: static;
          transform: unset;
        }
      }
    }
  }
/* #endregion */
